import type { ComponentType } from 'react'
import { useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import { useTheme } from 'src/components/contexts/ThemeContext'
import { Helmet } from 'react-helmet'
import { useLinxHomeView } from 'src/sdk/linx/hooks/useLinxHomeView'
import { getCMSPageByContentType } from 'src/utils/getCMSPageDataByContentType'
import BannerOurBrands from 'src/components/restructure/product/sections/BannerOurBrands'
import { RecommendationProvider } from 'src/components/restructure/product/contexts/recommendations-context'
import { ShelfGenericComponent } from 'src/components/restructure/product/sections/ShelfGeneric'
import { FindYourSport } from 'src/components/home/FindYourSport'
import { GridBanner } from 'src/components/home/GridBanner'
import { CollectionShelf } from 'src/components/common/CollectionShelf'
import TertiaryBanner from 'src/components/home/TertiaryBanner'
import { HeroBanner } from 'src/components/home/HeroBanner'
import { Pictos } from 'src/components/common/Pictos'

export interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string | null
        description: string | null
        titleTemplate: string | null
        siteUrl: string | null
      } | null
    } | null
  }
  serverData?: {
    cmsData?: {
      name: string
      sections?: any[]
    }
  }
}

const COMPONENTS: Record<string, ComponentType<any>> = {
  'Nossas Marcas [Home]': BannerOurBrands,
  'Vitrine Linx [Home]': ShelfGenericComponent,
  'Banners Secundários [Home]': GridBanner,
  'Vitrine Editorial [Home]': CollectionShelf,
  'Encontre seu esporte [Home]': FindYourSport,
  'Banners Terciários [Home]': TertiaryBanner,
  'Hero Banner [Home]': HeroBanner,
  ' Pictos [Home]': Pictos,
}

function HomePage(props: Props) {
  const {
    data: { site },
  } = props

  const sections = props?.serverData?.cmsData?.sections

  const { theme, themes } = useTheme()
  const { sendHomeViewEvent } = useLinxHomeView()

  const { homeBackground } = themes.filter((item) => item.key === theme)[0]
    .styles

  const title = site?.siteMetadata?.title ?? ''
  const siteUrl = site?.siteMetadata?.siteUrl ?? `https://www.decathlon.com.br`

  useEffect(() => {
    sendHomeViewEvent()
  }, [sendHomeViewEvent])

  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="fkisNN8vSDhgiyx5x02y1EYS9yV0x40h6rtdCSG0O7s"
        />
        <meta
          name="google-site-verification"
          content="HjvXBtZM-X_hXq-NaXZKuGnlRH_kGcVpavHD5QxRHaU"
        />
      </Helmet>
      <main className={`${homeBackground}`}>
        <GatsbySeo
          noindex
          nofollow
          title={title}
          description={site?.siteMetadata?.description ?? ''}
          titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
          language="pt-br"
          canonical={siteUrl}
          openGraph={{
            type: 'website',
            url: siteUrl,
            title: title ?? '',
            description: site?.siteMetadata?.description ?? '',
          }}
        />
        <JsonLd
          json={{
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: siteUrl,
            potentialAction: {
              '@type': 'SearchAction',
              target: `${siteUrl}/pesquisa?q={search_term_string}`,
              'query-input': 'required name=search_term_string',
            },
          }}
        />
        <RecommendationProvider pageName="home">
          {sections?.map(({ name, data }) => {
            const Component = COMPONENTS[name]

            if (!Component) {
              console.info(
                `Could not find component for block ${name}. Add a new component for this block or remove it from the CMS`
              )

              return <></>
            }

            return <Component key={`cms-section-${name}`} {...data} />
          })}
        </RecommendationProvider>
      </main>
    </>
  )
}

export const querySSG = graphql`
  query HomeV2PageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
  }
`

export const getServerData = async () =>
  getCMSPageByContentType({
    slug: '[Site]+Redesign+Home+',
    contentType: 'home',
    passBySlugify: false,
  })

HomePage.displayName = 'HomePage'
export default mark(HomePage)
